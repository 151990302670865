export const Resource: any[] = [
  {
    h1: (
      <>
        <span style={{ color: '#0256FF' }}>售前</span>方案定制
      </>
    ),
    a: [
      '「深入调研」项目经理基于企业实际业务场景和公司背景、运营现状做深入调研',
      '「方案输出」理解把握企业需求和期望，为客户搭建劳动力管理信息化解决方案',
      '「咨询服务」快速响应客户的疑问和需求，依托行业经验，实时分析和解决客户业务问题'
    ],
    img: 'https://s.huiyunban.cn/1744265412113076224.png',
    left: 0
  },
  {
    h1: (
      <>
        实施部署——<span style={{ color: '#0256FF' }}>实施前</span>
      </>
    ),
    a: ['梳理客户功能逻辑，确认培训计划书', '准备基础设置信息，导入员工信息', '账号及权限初步设置'],
    img: 'https://s.huiyunban.cn/1744255683450114048.png',
    left: 1
  },
  {
    h1: (
      <>
        实施部署——<span style={{ color: '#0256FF' }}>实施中</span>
      </>
    ),
    a: [
      '专业培训师现场/线上实施，先易后难循序渐进',
      '指导相关人员亲自操作，保证熟悉各个模块',
      '实施后填写满意度问卷，确保实施质量，并记录实施进度表'
    ],
    img: 'https://s.huiyunban.cn/1744264351054827520.png',
    left: 0
  },
  {
    h1: (
      <>
        实施部署——<span style={{ color: '#0256FF' }}>实施后</span>
      </>
    ),
    a: [
      '实施模块的使用效果跟进，确保实施落地质量',
      '定期输出使用数据报表，确保业务价值得以体现',
      '需求实时反馈和解决，确保客户体验的顺畅',
      '定期组织2次培训，确保新员工使用顺畅度和新功能的布达'
    ],
    img: 'https://s.huiyunban.cn/1744260076845142016.png',
    left: 1
  },
  {
    h1: (
      <>
        客户<span style={{ color: '#0256FF' }}>成功</span>
      </>
    ),
    a: [
      '「技术支持」专属项目团队全程跟进，保证数据安全稳定',
      '「需求支持」1对1项目经理对接，记录和反馈客户每一个业务需求，保障体验',
      '「数据支持」产品关键使用数据按需整理，支持企业精细化劳动力管理'
    ],
    img: 'https://s.huiyunban.cn/1744260605990146048.png',
    left: 0
  }
]

/** 精选成功案例 */
export const SuccessCases = [
  {
    logo: 'https://s.huiyunban.cn/1900107085161435136.png',
    name: '读酥世家',
    items: [
      {
        title: '保障考勤真实，节省硬件成本',
        content: (
          <>
            通过慧云班小程序，员工扫码打卡结合 GPS 校验，从根本上杜绝作弊现象，考勤真实性达
            <span className='tips'> 100%</span>，同时节省了考勤机采购费用，实现
            <span className='tips'> 0 </span>元硬件投入。
          </>
        )
      },
      {
        title: '支调流程高效，成本核对零耗时',
        content: (
          <>
            慧云班支调申请流程极为便捷，1
            分钟内就能完成支援提交。成本自动拆分，一键即可完成核对，整个过程耗时为
            <span className='tips'> 0 </span>小时。
          </>
        )
      },
      {
        title: '依据营业数据，优化人力配置',
        content: (
          <>
            依据营业额数据，将门店营运时间按闲忙划分为 6
            段。高峰时段增加兼职人员，低谷时段减少全职，成功将全职率控制在
            <span className='tips'> 50% </span>
            以下，使人时效稳定保持在<span className='tips'> 132元/小时以上</span>。
          </>
        )
      }
    ]
  },
  {
    logo: 'https://s.huiyunban.cn/1900107021525454848.png',
    name: '九锅一堂',
    items: [
      {
        title: '降低人力成本',
        content: (
          <>
            操作简便的排班系统，将每周排班时长从约 4 小时锐减至<span className='tips'>30分钟</span>
            ，薪资基于考勤自动核算，算薪周期缩短至<span className='tips'> 0.5 天</span>，每月 1
            日前即可完成上月算薪工作。
          </>
        )
      },
      {
        title: '提升运营效能',
        content: (
          <>
            接入收银机获取实际营业额数据，对比店长预估与人工时效，助力店长
            <span className='tips'>增强</span>时效意识；
            <tr />
            借助支调功能、合理提高兼职比例，将人时效指标提升 <span className='tips'> 23%</span>
            ，突破至 <span className='tips'> 150元/小时以上</span>；
          </>
        )
      },
      {
        title: '实现排班考勤合规化',
        content: (
          <>
            排班与出勤情况可视化呈现，结合内部奖罚制度，实时展示考勤结果；
            <tr />
            排班率稳定在<span className='tips'> 96% </span>左右，工时匹配率从
            <span className='tips'> 60% </span>跃升至
            <span className='tips'> 95%</span>，出勤管理更规范、更轻松；
          </>
        )
      }
    ]
  },
  {
    logo: 'https://s.huiyunban.cn/1900106222988693504.png',
    name: '某五星级酒店',
    items: [
      {
        title: '高效报表定制，数据处理提速',
        content: (
          <>
            凭借强大定制拓展能力，快速生成超 <span className='tips'> 20 </span>
            种报表，涵盖人员变动、用餐情况、考勤核算、薪酬统计分析等。各类汇报数据一键可得，数据处理时间每月缩至
            <span className='tips'> 1 小时</span>以内。
          </>
        )
      },
      {
        title: '精准激励员工，降低离职比率',
        content: (
          <>
            定制夜班工时计算，自动核算夜班津贴，精准激励夜班员工。结合日 / 周 /
            月自动排班工时限额，提升员工满意度，离职率降低<span className='tips'> 15%</span>。
          </>
        )
      },
      {
        title: '智能预测人力，满足特殊需求',
        content: (
          <>
            依据历史营业数据与 AI
            智能预测进行排班，精准预判人力缺口。打通慧云班交付体系，实时获取真实岗位需求，迅速补充兼职与实习人员，节假日、宴会等特殊场合岗位满足率超
            <span className='tips'> 85%</span>。
          </>
        )
      }
    ]
  }
]

export const SuccessBrandList = [
  {
    img: 'https://s.huiyunban.cn/1900122678178615296.png',
    name: '读酥世家'
  },
  {
    img: 'https://s.huiyunban.cn/1900122805261832192.png',
    name: '姚稷大铁锅'
  },
  {
    img: 'https://s.huiyunban.cn/1900141938003152896.png',
    name: '一龙拉面'
  },
  {
    img: 'https://s.huiyunban.cn/1900142107323011072.png',
    name: '付小姐在成都'
  },
  {
    img: 'https://s.huiyunban.cn/1900123268120055808.png',
    name: '糯雅芳粥'
  },
  {
    img: 'https://s.huiyunban.cn/1900123393374556160.png',
    name: '由心咖啡'
  },
  {
    img: 'https://s.huiyunban.cn/1900122743228076032.png',
    name: '趁热集合'
  },
  {
    img: 'https://s.huiyunban.cn/1900122872664297472.png',
    name: '九街淑芬'
  },
  {
    img: 'https://s.huiyunban.cn/1900142025227898880.png',
    name: '九锅一堂'
  },
  {
    img: 'https://s.huiyunban.cn/1900123068882227200.png',
    name: '外婆家'
  },
  {
    img: 'https://s.huiyunban.cn/1900123138105020416.png',
    name: '陈香贵'
  },
  {
    img: 'https://s.huiyunban.cn/1900123338517254144.png',
    name: '新发现'
  }
]
